import axios from "axios";
// import router from "@/router";

axios.defaults.timeout = 5000; // 超时时间设置
axios.defaults.withCredentials = true; // true允许跨域
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'

// 创建axios实例
const service = axios.create({
  timeout: 100000,
  baseURL: process.env.VUE_APP_BASE_API,
})
// 响应拦截器
service.interceptors.response.use(
  (response) => {
    // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
    // 否则的话抛出错误
    if (response.data.code === 200) {
      return Promise.resolve(response.data);
    } else { 
      return Promise.resolve(response.data);
    }
  }
);


// request拦截器
service.interceptors.request.use(config => {
  if (localStorage.getItem('token')) {
    config.headers['Authorization'] = localStorage.getItem('token')
  }
  return config
}, error => {
    Promise.reject(error)
})

export default service

