import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
import VueClipBoard from 'vue-clipboard2'
Vue.use(VueClipBoard)

import AMap from 'vue-amap';
Vue.use(AMap);

AMap.initAMapApiLoader({
  key: "666dc698559fbab654577dd1ceeeec57",//高德开放平台申请的key值
  plugin: [
    "AMap.Autocomplete", //输入提示插件
    "AMap.PlaceSearch", //POI搜索插件
    "AMap.Scale", //右下角缩略图插件 比例尺
    "AMap.OverView", //地图鹰眼插件
    "AMap.ToolBar", //地图工具条
    "AMap.Geolocation", //定位控件，用来获取和展示用户主机所在的经纬度位置
    "AMap.Geocoder", // 逆地理编码,通过经纬度获取地址所在位置详细信息
    // 根据需求选用
  ]
});
window._AMapSecurityConfig = {
  securityJsCode: "403614905ec88a1dd1639fc3843b4790"
};

import './components/register'

// 全局拦截器,在进入需要用户权限的页面前校验是否已经登录
router.beforeResolve((to, from, next) => {
  next();
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
