<template>
<div id="app" name="app">
  <mHeader v-if="path!='/login'&&path!='/applet'"></mHeader>
  <router-view></router-view>
  <mFooter v-show="path!='/login'&&path!='/chat'&&path!='/applet'"></mFooter>
</div>
</template>

<script>
import { getCompanyInfo} from "@/api/index.js";
export default {
  data() {
    return {
      path: '',
      userInfo: {}
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        if (this.$route.path) {
          this.path = this.$route.path;
        }
      }
    }
  },
  mounted(){
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
      this.$router.push({
        path: '/applet'
      })
    } else if (/(Android)/i.test(navigator.userAgent)) {
      this.$router.push({
        path: '/applet'
      })
    } 
    this.getCompanyInfo()
  },
  methods: {
     getCompanyInfo(){
       getCompanyInfo().then(res=>{
        localStorage.setItem('companyInfo',JSON.stringify(res.data))
      })
    },
  }
};
</script>

<style lang="scss">
@import './assets/iconfont/iconfont.css';
html {
  
  scroll-behavior: smooth;
  font-family: arial,verdana,helvetica,'PingFang SC','HanHei SC',STHeitiSC-Light,Microsoft Yahei,sans-serif;
}
::-webkit-scrollbar {
    width: 4px;
    height:4px;
    background-color: #f5f5f5;
}

::-webkit-scrollbar-button:hover {
    background-color: #aaa
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #e1e1e1
}

::-webkit-scrollbar-thumb:hover {
    background-color: #e1e1e1
}

::-webkit-scrollbar-track,::-webkit-scrollbar-track:hover {
    background-color: transparent
}

.search-box {
  .searchCt {
    width: 770px;
    float: left;

    .el-input__inner {
      height: 50px;
      border-left: none;
      border-right: none;
       border-radius:8px 8px 8px 8px;
      border: none;
      font-size: 16px;
      color: #414a60;
    }

  }

  margin-left: 150px;
  height: 52px;
  width:900px;
  margin-bottom: 15px;
  background: #0077FF;
  padding-top: 2px;
  padding-left: 2px;
  border-radius:10px;

  .search-icon {
    color: #fff;
    text-align: center;
    float: left;
    cursor: pointer;
    width: 128px;
    height: 50px;
    line-height: 50px;
    font-size: 20px;
    font-weight: 700;
    background: #0077FF;
    border-radius: 0px 10px 10px 0px;

    img {
      width: 31px;
      height: 31px;
      position: relative;
      top: 15px;
    }
  }
}
.filter-ct{
  margin-top: 13px;
  
   input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #222222;
  }
  .el-input__inner{
    width: 141px;
    height: 31px;
    line-height: 31px;
    border:none;
    border-radius: 5px;
    font-size: 14px;
    color: #222222;
    background: #f8f8f8;
  }
  .el-select{
    margin-right: 10px;
  }
  .el-input__icon{
    line-height: 31px
  }
  .drop-text{
    background: #f8f8f8;
    padding: 7px 15px;
    cursor: pointer;
    font-size: 14px !important;
  }
}
.subdivision-menu{
  width: 440px;
  padding: 15px 0 15px 0;
  .el-dropdown-menu__item{
    display: inline-block;
    min-width: 130px;
    height: 30px;
    margin-right: 15px;
    line-height: 30px;
    text-align: center;
    color: #222;
    font-size: 14px;
    padding: 0;
    cursor: pointer;
  }
  .el-dropdown-menu__item:hover{
    color: #0077FF;
    background: #f8f8f8;
  }
}
@import "./assets/css/common.scss";
</style>
