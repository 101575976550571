<template>
<div id="footer">
  <div class="f-wrap">
    <ul>
      <li class="f-title">联系我们</li>
      <li>{{companyInfo.corporateName}} </li>
      <li>公司地址：{{companyInfo.corporateAddress}}</li>
      <li>服务热线/举报渠道：{{companyInfo.serviceHotline}}</li>
    </ul>
    <ul style="width:250px">
      <li class="f-title">使用帮助</li>
      <li>协议与规则</li>
      <li>隐私协议</li>
      <li>使用帮助</li>
    </ul>
    <div class="wx">
      <img :src="companyInfo.tiktokPic" />
      <span>抖音</span>
    </div>
    <div class="wx">
      <img :src="companyInfo.smallRoutinePic" />
      <span>微信小程序</span>
    </div>
    <div class="wx">
      <img :src="companyInfo.officialAccountPic" />
      <span>微信公众号</span>
    </div>
    <div class="copyright">
      <span> Copyright @ 2023 {{companyInfo.corporateName}}</span>
      <span><a href="https://beian.miit.gov.cn/" target="_blank">新ICP备2023000384号-1</a></span>
    </div>
  </div>

</div>
</template>

<script>
export default {
  name: "mFooter",
  data() {
    return {
      companyInfo:{}
    }
  },
  mounted(){
    this.companyInfo = JSON.parse(localStorage.getItem('companyInfo'))
  },
  methods: {

  }
};
</script>

<style lang="scss" scoped>
#footer {
  clear: both;
  width: 100%;
  height: 220px;
  background: #333333;
  padding: 50px 0 30px;
}

.f-wrap {
  width: 1200px;
  margin: 0 auto;

  ul {
    width: 530px;
    float: left;

    .f-title {
      font-size: 20px;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 36px;
      margin-bottom: 12px;
    }

    li {
      font-size: 16px;
      color: #999999;
      line-height: 30px;
    }
  }

  .wx {
    float: right;
    margin-left: 20px;

    img {
      width: 120px;
      height: 120px;
    }

    span {
      display: block;
      text-align: center;
      font-size: 16px;
      color: #FFFFFF;
      line-height: 30px;
      margin-top: 4px;
    }
  }
}

.copyright {
  clear: both;
  font-size: 14px;
  height: 40px;
  font-weight: 400;
  width: 719px;
  color: #999999;
  line-height: 40px;
  position: relative;
  top: 25px;
  border-top: 1px solid #fff;

  span {
    display: inline-block;
    margin-right: 24px;
    a:link {
      color: #999999;
    }
    a:visited {
      color: #999999;
    }
  }
}
</style>
