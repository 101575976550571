/*
 * @Description: 路由配置
 * @Author: hai-27
 * @Date: 2020-02-07 16:23:00
 * @LastEditors: hai-27
 * @LastEditTime: 2020-02-27 13:58:48
 */
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const routes = [

  {
    path: '/',
    name: 'home',
    component: () => import('../views/home.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/home.vue')
  },
  {
    path: '/resume',
    name: 'resume',
    component: () => import('../views/resume/resume.vue')
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('../views/search.vue')
  },
  {
    path: '/jobDetail',
    name: 'jobDetail',
    component: () => import('../views/jobDetail.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue')
  },
  {
    path: '/company',
    name: 'company',
    component: () => import('../views/company.vue')
  },
  {
    path: '/companyDetail',
    name: 'companyDetail',
    component: () => import('../views/company/companyDetail.vue')
  },
  {
    path: '/addContacts',
    name: 'addContacts',
    component: () => import('../views/company/addContacts.vue')
  },
  {
    path: '/mine',
    name: 'mine',
    component: () => import('../views/company/mine.vue')
  },
  {
    path: '/account',
    name: 'account',
    component: () => import('../views/company/account.vue')
  },
  {
    path: '/publish',
    name: 'publish',
    component: () => import('../views/company/publish.vue')
  },
  {
    path: '/addCompany',
    name: 'addCompany',
    component: () => import('../views/company/addCompany.vue')
  },
  {
    path: '/resumeDetail',
    name: 'resumeDetail',
    component: () => import('../views/resume/resumeDetail.vue')
  },
  {
    path: '/resumeList',
    name: 'resumeList',
    component: () => import('../views/resume/resumeList.vue')
  },
  {
    path: '/lawsList',
    name: 'lawsList',
    component: () => import('../views/lawsList.vue')
  },
  {
    path: '/verify',
    name: 'verify',
    component: () => import('../views/company/verify.vue')
  },
  {
    path: '/jobList',
    name: 'jobList',
    component: () => import('../views/jobList.vue')
  },
  {
    path: '/joinCompany',
    name: 'joinCompany',
    component: () => import('../views/company/joinCompany.vue')
  },
  {
    path: '/lawsDetail',
    name: 'lawsDetail',
    component: () => import('../views/lawsDetail.vue')
  },
  {
    path: '/chat',
    name: 'chat',
    component: () => import('../views/chat.vue')
  },
  {
    path: '/personal',
    name: 'personal',
    component: () => import('../views/jobHunter/personal.vue')
  },
  {
    path: '/footmark',
    name: 'footmark',
    component: () => import('../views/footmark.vue')
  },
  {
    path: '/trendList',
    name: 'trendList',
    component: () => import('../views/trendList.vue')
  },
  {
    path: '/trendDetail',
    name: 'trendDetail',
    component: () => import('../views/trendDetail.vue')
  },
  {
    path: '/positionList',
    name: 'positionList',
    component: () => import('../views/jobHunter/positionList.vue')
  },
  {
    path: '/setting',
    name: 'setting',
    component: () => import('../views/jobHunter/setting.vue')
  },
  {
    path: '/applet',
    name: 'applet',
    component: () => import('../views/applet.vue')
  },
  {
    path: '/popularList',
    name: 'popularList',
    component: () => import('../views/jobHunter/popularList.vue')
  },
  {
    path: '/jobFairs',
    name: 'jobFairs',
    component: () => import('../views/jobHunter/jobFairs.vue')
  },
  {
    path: '/agreement',
    name: 'agreement',
    component: () => import('../views/agreement.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/about.vue')
  },
  {
    path: '/joinUs',
    name: 'joinUs',
    component: () => import('../views/jobHunter/joinUs.vue')
  },
  {
    path: '/market',
    name: 'market',
    component: () => import('../views/market/index.vue')
  },
  {
    path: '/applyInfo',
    name: 'applyInfo',
    component: () => import('../views/market/applyInfo.vue')
  },
  {
    path: '/apply',
    name: 'apply',
    component: () => import('../views/market/apply.vue')
  },
  {
    path: '/shop',
    name: 'shop',
    component: () => import('../views/shop.vue')
  },
  {
    path: '/merchant',
    name: 'merchant',
    component: () => import('../views/market/merchant.vue')
  },
  {
    path: '/service',
    name: 'service',
    component: () => import('../views/market/service.vue')
  },
  {
    path: '/license',
    name: 'license',
    component: () => import('../views/license.vue')
  },
  {
    path: '/success',
    name: 'success',
    component: () => import('../views/market/success.vue')
  },
  {
    path: '/paySucess',
    name: 'paySucess',
    component: () => import('../views/market/paySucess.vue')
  }
]

const router = new Router({
  // base: '/dist',
  // mode: 'history',
  routes
})

/* 由于Vue-router在3.1之后把$router.push()方法改为了Promise。所以假如没有回调函数，错误信息就会交给全局的路由错误处理。
vue-router先报了一个Uncaught(in promise)的错误(因为push没加回调) ，然后再点击路由的时候才会触发NavigationDuplicated的错误(路由出现的错误，全局错误处理打印了出来)。*/
// 禁止全局路由错误处理打印
const originalPush = Router.prototype.push;
Router.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

export default router
