<template>
<div class="home-box">
  <div class="home-top">
      <div class="bannerText">
        <img src="@/assets/imgs/bannerTop.png" />
      </div>
  </div>
  <div id="header">
    <div class="h-innder" v-if="userInfo.type==2">
      <router-link :to="{path:'/'}">
        <div class="logo">
          <img src="@/assets/imgs/logo1.png" />
          <span>哈密人才招聘网 | 企业版</span>
        </div>
      </router-link>
      <div class="nav">
        <ul>
          <router-link :to="{path:'/home'}">
            <li>首页</li>
          </router-link>
          <router-link :to="{path:'/resumeList'}">
            <li>人才</li>
          </router-link>
          <router-link :to="{path:'/trendList'}">
            <li>公司动态</li>
          </router-link>
          <router-link :to="{path:'/lawsList'}">
            <li>服务之窗</li>
          </router-link>
          <router-link :to="{path:'/about'}">
            <li>关于我们</li>
          </router-link>
          <li @click="jumpMarket" v-show="joblessSwitch">零工市场</li>
        </ul>
      </div>
      <div class="user-nav" v-show="userInfo.phone">
        <router-link :to="{path:'/chat'}">
          <div class="nav-item">消息</div>
        </router-link>
        <el-dropdown class="wx-server">
            <div class="el-dropdown-link nav-item">
              <img class="wx-btn" src="@/assets/imgs/personal/wx.png" />
              公众号
            </div>
          <el-dropdown-menu class="header-menu" slot="dropdown">
            <img class="wx-pic" :src="companyInfo.officialAccountPic" />
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown class="avator-link">
          <div class="el-dropdown-link nav-item ">
            <!-- <span>{{userInfo.userName}}</span> -->
            <p class="companyName">
                <i :class="{activeName:userInfo.enterpriseName.length<=8}">{{userInfo.enterpriseName.slice(0,8)}}</i>
                <i class="enterpriseSecond">{{userInfo.enterpriseName.slice(8,40)}}</i>
            </p>
            <img :src="userInfo.headSculpture" />
          </div>
          <el-dropdown-menu class="header-menu" slot="dropdown">
            <router-link :to="{path:'/account'}">
              <el-dropdown-item>账号设置</el-dropdown-item>
            </router-link>
            <router-link :to="{path:'/mine'}">
              <el-dropdown-item>个人中心</el-dropdown-item>
            </router-link>
            <div @click="toggle"><el-dropdown-item>切换身份</el-dropdown-item></div>
            <div @click="jumpManage"><el-dropdown-item>企业身份管理</el-dropdown-item></div>
<!--            <router-link :to="{path:'/merchant'}" v-show="merchantOrNot">
              <el-dropdown-item>商户中心</el-dropdown-item>
            </router-link>-->
            <div @click="logOut">
              <el-dropdown-item>退出登陆</el-dropdown-item>
            </div>
          </el-dropdown-menu>
        </el-dropdown>
        <div class="logout-text nav-item" @click="logOut">退出</div>
      </div>
    </div>
    <div class="h-innder" v-else-if="userInfo.type==1">
      <router-link :to="{path:'/'}">
        <div class="logo">
          <img src="@/assets/imgs/logo1.png" />
          <span>哈密人才招聘网</span>
        </div>
      </router-link>
      <div class="nav">
        <ul>
          <router-link :to="{path:'/home'}">
            <li>首页</li>
          </router-link>
          <router-link :to="{path:'/positionList'}">
            <li>职位</li>
          </router-link>
          <router-link :to="{path:'/trendList'}">
            <li>公司动态</li>
          </router-link>
          <router-link :to="{path:'/lawsList'}">
            <li>服务之窗</li>
          </router-link>
          <router-link :to="{path:'/about'}">
            <li>关于我们</li>
          </router-link>
          <li @click="jumpMarket" v-show="joblessSwitch">零工市场</li>
        </ul>
      </div>

      <div class="user-nav" v-show="userInfo.type==3">
        <label @click="login">登陆/注册</label>
      </div>
      <div class="user-nav" v-show="userInfo.type==1">
        <router-link :to="{path:'/chat'}">
            <div class="nav-item">消息</div>
        </router-link>
        <el-dropdown class="wx-server">
            <div class="el-dropdown-link nav-item">
              <img class="wx-btn" src="@/assets/imgs/personal/wx.png" />
              公众号
            </div>
          <el-dropdown-menu class="header-menu" slot="dropdown">
            <img class="wx-pic" :src="companyInfo.officialAccountPic" />
          </el-dropdown-menu>
        </el-dropdown>

        <el-dropdown class="avator-link">
          <div class="el-dropdown-link headSculpture nav-item">
              <strong>{{userInfo.userName}}</strong>
              <img :src="userInfo.headSculpture" />
          </div>
          <el-dropdown-menu class="header-menu" slot="dropdown">
            <router-link :to="{path:'/resume'}">
              <el-dropdown-item>在线简历</el-dropdown-item>
            </router-link>
            <router-link :to="{path:'/personal'}">
              <el-dropdown-item>个人中心</el-dropdown-item>
            </router-link>
            <div @click="toggle"><el-dropdown-item>切换身份</el-dropdown-item></div>
            <router-link :to="{path:'/setting'}">
              <el-dropdown-item>账号设置</el-dropdown-item>
            </router-link>
            <router-link :to="{path:'/merchant'}" v-show="merchantOrNot">
              <el-dropdown-item>商户中心</el-dropdown-item>
            </router-link>
            <div @click="logOut">
              <el-dropdown-item>退出登陆</el-dropdown-item>
            </div>
          </el-dropdown-menu>
        </el-dropdown>
        <div class="logout-text nav-item" @click="logOut">退出</div>
      </div>
    </div>
    <div class="h-innder" v-else>
      <router-link :to="{path:'/'}">
        <div class="logo">
          <img src="@/assets/imgs/logo1.png" />
          <span>哈密人才招聘网</span>
        </div>
      </router-link>
      <div class="nav">
        <ul>
          <router-link :to="{path:'/home'}">
            <li>首页</li>
          </router-link>
          <router-link :to="{path:'/positionList'}">
            <li>职位</li>
          </router-link>
          <router-link :to="{path:'/trendList'}">
            <li>公司动态</li>
          </router-link>
          <router-link :to="{path:'/lawsList'}">
            <li>服务之窗</li>
          </router-link>
          <router-link :to="{path:'/about'}">
            <li>关于我们</li>
          </router-link>
          <li @click="jumpMarket" v-show="joblessSwitch">零工市场</li>
        </ul>
      </div>

      <div class="user-nav" v-show="userInfo.type==3">
        <label @click="login">登陆/注册</label>
      </div>
      <div class="user-nav" v-show="userInfo.type==0">
        <el-dropdown class="wx-server">
          <div class="el-dropdown-link nav-item">
            <img class="wx-btn" src="@/assets/imgs/personal/wx.png" />
            公众号
          </div>
          <el-dropdown-menu class="header-menu" slot="dropdown">
            <img class="wx-pic" :src="companyInfo.officialAccountPic" />
          </el-dropdown-menu>
        </el-dropdown>

        <el-dropdown class="avator-link">
          <div class="el-dropdown-link headSculpture nav-item">
            <strong>{{userInfo.userName}}</strong>
            <img :src="userInfo.headSculpture" />
          </div>
          <el-dropdown-menu class="header-menu" slot="dropdown">
            <router-link :to="{path:'/merchant'}" v-show="merchantOrNot">
              <el-dropdown-item>商户中心</el-dropdown-item>
            </router-link>
            <div @click="logOut">
              <el-dropdown-item>退出登陆</el-dropdown-item>
            </div>
          </el-dropdown-menu>
        </el-dropdown>
        <div class="logout-text nav-item" @click="logOut">退出</div>
      </div>
    </div>

  </div>
</div>
</template>

<script>
import { logOut, getUserInfo,selectIdentity,getMerchantOrNot,getJoblessSwitch} from "@/api/index.js";
export default {
  name: "mHeader",
  data() {
    return {
      userInfo: {
        type:3
      },
      companyInfo:{},
      merchantOrNot:false,
      joblessSwitch:true
    }
  },
  mounted() {
    this.getUserInfo()
    this.getMerchantOrNot()
    this.getJoblessSwitch()
    this.companyInfo = JSON.parse(localStorage.getItem('companyInfo'))
  },
  methods: {
    jumpMarket(){
        this.$router.push({
           path: '/market'
        })
    },
    getJoblessSwitch(){
      getJoblessSwitch().then(res=>{
        this.joblessSwitch = res.data.joblessSwitch
      })
    },
    getMerchantOrNot(){
      getMerchantOrNot().then(res=>{
          if(res.data != undefined) {
            this.merchantOrNot = res.data.merchantOrNot
          }
      })
    },
    jumpManage(){
      this.$router.push({
        path:'/account',
        query:{
          type:9
        }
      })
    },
    getUserInfo() {
      getUserInfo().then(res => {
        if (res.code == 200) {
          this.userInfo = res.data
          if( !this.userInfo.enterpriseName){
            this.userInfo.enterpriseName = '没有绑定企业'
          }
          localStorage.setItem('userInfo', JSON.stringify(res.data))
        } else {
          this.userInfo.type = 3
          localStorage.setItem('userInfo', JSON.stringify({type:3}))
        }
      })
    },
    toggle(){
      if(this.userInfo.type==1){
          this.$confirm('确认要切换成企业吗?', '切换身份', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
             this.$router.push({
              path:'/'
            })
            this.selectIdentity(2)
          })
      }else{
         this.$confirm('确认要切换成求职者身份吗?', '切换身份', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
             this.$router.push({
              path:'/'
            })
            this.selectIdentity(1)
          })
      }

    },
    selectIdentity(type){
      selectIdentity({
        type: type
      }).then(() => {
         getUserInfo().then(res => {
          this.$router.go(0)
          if (res.code == 200) {
            this.userInfo = res.data
            localStorage.setItem('userInfo', JSON.stringify(res.data))
          } else {
            localStorage.setItem('userInfo', JSON.stringify({type:1}))
          }
        })
      })
    },
    logOut() {
      logOut().then(res => {
        if (res.code == 200) {
          this.$message({
            message: '退出登陆成功！',
            type: 'success'
          })
          this.$router.push({
            path: '/login'
          })
          localStorage.setItem('userInfo', JSON.stringify({type:3}))
        }
      })
    },

    login() {
      this.$router.push({
        path: '/login'
      })
    }
  }
};
</script>

<style lang="scss" scoped>
#header {
   width: 100%;
    height: 50px;
    line-height: 50px;
    background: #020202;
    box-shadow: 0 1px 8px rgba(0,0,0,.06);
    position: relative;
    z-index: 1005;
  overflow: hidden;
  transition: all linear .2s;
}
.home-top{
  width: 100%;
  background: #E9F8FF;
  .bannerText{
     width: 1200px;
      margin: 0 auto;
    img{
     width: 100%;
    }
  }
}
.h-innder {
  width: 1200px;
  margin: 0 auto;
}

.logo {
  float: left;

  img {
    width: 37px;
    height:37px;
    position: relative;
    top:7px;
    margin-right:15px;
    float: left;
  }

  span {
    font-size: 14px;
    color: #fff;
    letter-spacing: 1px;
  }

}

.nav {
  float: left;
  margin-left: 65px;
  font-size: 14px;

  li {
    display: inline-block;
    vertical-align: top;
    text-align: center;
    margin-right: 32px;
    cursor: pointer;
    font-weight: 500;
    color: #fff;
    transition: all linear .2s;
  }

  li:hover {
    color: #0077FF;
  }
}

.user-center {
  margin-left: 20px;
}

.user-nav {
  float: right;
  cursor: pointer;
  height: 65px;

  .nav-item {
    display: block;
    cursor: pointer;
    float: left;
    font-weight: 500;
    color: #fff;
    font-size: 14px;
  }
  .nav-item:hover {
    color: #0077FF;
    transition: all linear .2s;
  }
  img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    position: relative;
    margin-left: 20px;
  }

  label {
    display: inline-block;
    width: 91px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    border: 1px solid #fff;
    border-radius: 23px;
    margin-right: 10px;
    font-size: 14px;
    cursor: pointer;
    color: #fff;
  }
  .logout-text{
    float: right;
    margin-left: 10px;
  }
}
.avator-link{
  .companyName{
    width: 100px;
    font-size: 12px;
    float: left;
    i{
      display: block;
      font-style: normal;
      height: 20px;
      line-height: 20px;
      overflow: hidden;

    }
    .activeName{
      position: relative;
      top: 10px;
      text-align: right;
    }
    .enterpriseSecond{
      width: 100px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow:ellipsis
    }
  }
  .el-dropdown-link{
      border-radius: 6px;
      padding: 0 8px;
      transition: all .2s linear;
      position: relative;
      top: 8px;
      margin-left: 10px;
      img{
        float: left;
        margin-top: 3px;
        margin-left: 10px;
      }
  }
  .headSculpture{
    float: left;
    height: 40px;
    line-height: 40px;
    top:6px;
    strong{
      font-weight: 100;
       float: left;
    }
    img{
      float: left;
    }
  }
  .el-dropdown-link:hover{
    background:rgba(255,255,255,0.2);
    span{
      color: #fff;
    }
  }
}
.wx-server{
   float: left;
  .wx-btn{
    width: 23px;
    height: 23px;
    position: relative;
    top:7px;
    right:3px
  }
}
.wx-pic{
  width: 130px;
  margin:10px 15px
}
</style><style lang="scss">
.header-menu{
  top: 105px !important;
  width: 160px;
  min-height: 120px;
  background: #FFFFFF;
  box-shadow: 0px 5px 15px 0px rgba(183, 183, 183, 0.3);
  border-radius: 5px;
  padding-bottom: 0;
  padding-top: 2px;

  .el-dropdown-menu__item {
    font-size: 15px;
    height: 45px;
    color: #222222;
    line-height: 45px;
    padding-left: 30px;
    border-bottom: 1px solid #eee;
  }

  .el-dropdown-menu__item:hover {
    background: #f8f8f8;
    color: #0077FF;
  }
}
 .unreadMsg{
    .el-badge__content.is-fixed{
       position: relative;
       top: 4px;
       right: 20px;
    }
    .el-badge__content{
      background: #fe574a;
    }
  }
</style>
