
export default {
  state: {
    goodsList: [], 
  },
  getters: {
    getGoodsList (state) {
      return state.goodsList
    },
  },
  mutations: {
    setList (state, data) {
      state.goodsList = data;
    },
  },
  actions: {
    // setUser ({ commit }, data) {
    //   commit('setUser', data);
    // },
    // searchProductInfo().then(res => { })
  }
}